import { httpGet, httpPost, httpPut } from "@/api/request";
import $store from "@/store";

// 获取巡更列表
export function GET_PATROL_LIST(params) {
    return httpGet("/patrol", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取巡更-电杆列表
export function GET_PATROL_POLE_LIST(params) {
    return httpGet(`/patrol/datail/pole/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取巡更-打卡列表
export function GET_PATROL_CARD_LIST(params) {
    return httpGet(`/patrol/datail/card/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取巡更-循环列表
export function GET_PATROL_LOG_LIST(params) {
    return httpGet(`/patrol/datail/log/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取所有巡更单位
export function GET_PATROL_UNIT_LIST(params) {
    return httpGet("/patrol/units", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取巡更-收货单
export function GET_PATROL_POLE_ORDER(params) {
    return httpGet("/patrol/pole/list", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取巡更-收货单电杆列表
export function GET_PATROL_POLE_DETAIL(params) {
    return httpGet(`/patrol/pole/detail/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 新增巡更
export function ADD_PATROL(params) {
    return httpPost("/patrol", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 新增巡更打卡
export function ADD_PATROL_PUNCH(params) {
    return httpPut(`/patrol/units/add/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 巡护单位 打卡任务列表
export function GET_PATROL_UNITS_LIST(params) {
    return httpGet("/patrol/units/list", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}
