<template>
    <div class="patrollist-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="新　增" to="/client/patrol/add" />
                <van-tab replace title="任务列表" to="/client/patrol/list" />
            </van-tabs>
        </div>
        <div class="patrollist-body">
            <van-cell :title="item.name" @click="onDelete" v-for="(item, index) in dataList">
                <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                <template #value>
                    <div class="status2" v-if="item.status === 0">超时</div>
                    <div class="status1" v-if="item.status === 1">正常</div>
                    <div class="status1" v-if="item.status === 2">循环</div>
                </template>
            </van-cell>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { GET_PATROL_LIST } from "@/api/patrol";
import { Dialog } from "vant";

onMounted(() => {
    getDataList();
});

let tabActive = ref(1);

// 获取列表
let dataList = ref([]);

let getDataList = () => {
    GET_PATROL_LIST({
        status: 0,
        limit: 10000
    }).then((res) => {
        dataList.value = res.data.data.list;
        console.log(res.data.data);
    });
};

// 删除
let onDelete = () => {
    return;
    Dialog.confirm({
        title: "是否删除该任务？",
        message: "该操作不可逆，是否删除？"
    }).then(() => {});
};
</script>

<style lang="scss" scoped>
.patrollist-page {
    min-height: 100vh;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .patrollist-body {
        margin-top: 20px;

        .status1 {
            color: #3ed65f;
        }

        .status2 {
            color: #d43030;
        }
    }
}
</style>
